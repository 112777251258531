/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    strong: "strong",
    h3: "h3",
    a: "a",
    hr: "hr",
    h4: "h4",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Esta encontrando dificuldades ao utilizar um dos nossos ecossistemas? Preparamos aqui uma seleção de artigos que abordam possíveis dúvidas e dificuldades para te auxiliar."), "\n", React.createElement(_components.h2, null, React.createElement(_components.strong, null, "Tire suas dúvidas e acesse ajuda aqui")), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Não tenho currículo")), "\n", React.createElement(_components.p, null, "Acesse nosso artigo de ", React.createElement(_components.a, {
    href: "https://vendoorvagas.com.br/blog/como-preparar-seu-curriculo/"
  }, "como criar um currículo aqui"), "."), "\n", React.createElement("br"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Não consigo me cadastrar")), "\n", React.createElement(_components.p, null, "Acesse nosso artigo de ", React.createElement(_components.a, {
    href: "https://vendoorvagas.com.br/blog/como-criar-minha-conta-no-vendoor/"
  }, "como criar minha conta"), "."), "\n", React.createElement("br"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Quero ver como anda meus processos atuais")), "\n", React.createElement(_components.p, null, "Acesse nosso artigo de ", React.createElement(_components.a, {
    href: "https://vendoorvagas.com.br/blog/processos-em-andamento-e-controles-de-candidaturas/"
  }, "como ver meus processos"), "."), "\n", React.createElement("br"), "\n", React.createElement(_components.h3, null, React.createElement(_components.strong, null, "Ver novas vagas")), "\n", React.createElement(_components.p, null, "Acesse nosso artigo de ", React.createElement(_components.a, {
    href: "https://vendoorvagas.com.br/blog/busque-vagas-de-vendedor-em-goiania-estrategias-eficientes/"
  }, "como ver vagas"), "."), "\n", React.createElement("br"), "\n", React.createElement(_components.h2, null, React.createElement(_components.strong, null, "Ainda tem dúvidas?")), "\n", React.createElement(_components.p, null, "Entre em contato com o nosso suporte através da plataforma Vendoor ou consulte mais artigos no nosso blog, estaremos sempre atualizando com novos tutoriais e dicas."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h4, null, "Créditos"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Autor do artigo:"), " Lisanna Reis\n", React.createElement("br"), React.createElement(_components.em, null, "Atualizado pela última vez em"), " 28/02/2025\n", React.createElement("br"), "Foto de ", React.createElement(_components.a, {
    href: "https://www.pexels.com/pt-br/foto/mulher-de-sueter-cinza-segurando-um-iphone-prateado-6-6383268/"
  }, "Liza Summer")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
